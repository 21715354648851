// ? Next js
// ? Material Ui
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'hooks';
import Link from 'next/link';

function SubMenuItem({ icon, title, caption, path }: ISubMenuItems) {
  return (
    <List sx={{ width: '100%', maxWidth: 360 }} disablePadding>
      <ListItem selected={useNavigate(path)} sx={{ p: 0 }}>
        <Link href={path} passHref>
          <ListItemButton sx={{ py: 0.7 }} component="a">
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
              primary={title}
              primaryTypographyProps={{ fontSize: 16 }}
              secondary={caption}
            />
          </ListItemButton>
        </Link>
      </ListItem>
    </List>
  );
}

export default SubMenuItem;
