import Image from 'next/image';
import * as React from 'react';
import { API } from '_helpers';

const Logo: React.FC<IProps<IMedia>> = ({ data }) => {
  return (
    <Image
      alt={data.alternativeText}
      src={`${API}${data.url}`}
      width={data.width}
      height={data.height}
      priority
    />
  );
};

export default Logo;
