import Blog from '@mui/icons-material/ArticleTwoTone';
import About from '@mui/icons-material/InfoTwoTone';
import Training from '@mui/icons-material/LocalLibraryTwoTone';
// ? Icons
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Gallery from '@mui/icons-material/PhotoLibraryTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
// ? Assets
import Logo from 'components/logo';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toggleDeskDrawer } from 'store/actions/menu';
import DrawerDesk from '../drawerDesk';
import LinkItem, { MenuItem } from '../linkItem';
import SubMenuItem from '../submenuItem';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';

// ? Custom style
const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const LogoContainer = styled(Stack)({
  width: 150,
  justifyContent: 'center',
});

interface ISubMenu {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  children: any;
}

function SubMenu({ anchorEl, open, handleClose, children }: ISubMenu) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 2,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px #0000001c)',
          borderRadius: 4,
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: '50%',
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  );
}

interface IMenuProps {
  data: IMenu;
  logo: IMedia;
}

const MenuDesk: React.FC<IMenuProps> = ({ data, logo }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { route, push } = useRouter();

  const dispatch = useDispatch();

  const subMenuIcons = [
    {
      icon: <About color="primary" fontSize="large" />,
    },
    {
      icon: <Gallery color="primary" fontSize="large" />,
    },
    {
      icon: <WorkTwoToneIcon color="primary" fontSize="large" />,
    },

    {
      icon: <Blog color="primary" fontSize="large" />,
    },
    {
      icon: <Training color="primary" fontSize="large" />,
    },
    {
      icon: <QuestionAnswerTwoToneIcon color="primary" fontSize="large" />,
    },
  ];

  const subMenuPaths = data.subMenuItems.map((el) => el.path);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = (path: string) => {
    return route === path ? true : false;
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      dispatch(toggleDeskDrawer(open));
    };
  return (
    <Root>
      <DrawerDesk toggleDrawer={toggleDrawer} />
      <AppBar
        sx={{ backgroundColor: 'background.paper' }}
        elevation={0}
        variant="outlined"
      >
        <Toolbar>
          <Container sx={{ flexGrow: 1, py: 1 }} maxWidth="lg">
            <Stack direction="row" justifyContent="space-between">
              <LogoContainer onClick={() => push('/')}>
                <Logo data={logo} />
              </LogoContainer>
              <Stack direction="row" alignItems="center" spacing={2}>
                {data.menuItems.slice(0, 4).map((el, i) => (
                  <LinkItem
                    key={el._id}
                    path={el.path}
                    content={el.label}
                    active={isActive(el.path)}
                  />
                ))}
                {data.menuItems.slice(4, 5).map((el, i) => (
                  <LinkItem
                    path=""
                    key={el._id}
                    content={el.label}
                    multiple
                    handleClick={handleClick}
                    active={subMenuPaths.includes(`/${route.split('/')[1]}`)}
                  />
                ))}

                <SubMenu
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                >
                  {data.subMenuItems.map((el, i) => (
                    <SubMenuItem
                      key={el._id}
                      {...el}
                      icon={subMenuIcons[i]?.icon}
                    />
                  ))}
                </SubMenu>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Divider
                  orientation="vertical"
                  textAlign="center"
                  variant="inset"
                />
                {data.menuItems.slice(5, 6).map((el, i) => (
                  <LinkItem
                    key={el._id}
                    path={el.path}
                    content={el.label}
                    active={isActive(el.path)}
                  />
                ))}
                <Stack direction="row" spacing={2}>
                  <a
                    href={`${data.buttons[0].path}`}
                    target="_blank"
                    title="Updev community"
                    rel="noopener noreferrer"
                  >
                    <Button variant="outlined" color="secondary">
                      {data.buttons[0].label}
                    </Button>
                  </a>
                  <Link href={`${data.buttons[1].path}`} passHref>
                    <Button variant="contained" component="a" disableElevation>
                      {data.buttons[1].label}
                    </Button>
                  </Link>
                </Stack>
                <MenuItem>
                  <Tooltip
                    describeChild
                    title={data.tooltip}
                    arrow
                    onClick={toggleDrawer(true)}
                  >
                    <IconButton>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </MenuItem>
              </Stack>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
    </Root>
  );
};

export default MenuDesk;
