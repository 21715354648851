// ? Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Stack from '@mui/material/Stack';
// ? Material UI
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// ? Next js
import Link from 'next/link';
import React from 'react';

interface ILink {
  active?: any;
}
interface IMenuItem extends ILink {
  path: string;
  content: any;
  multiple?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const MenuItem = styled(Stack)<ILink>(({ theme, active }) => ({
  color: active ? theme.palette.secondary.main : theme.palette.text.primary,
  alignItems: 'center',
  flexDirection: 'row',
  fontSize: 14,
  fontWeight: active ? 700 : 500,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

function LinkItem({ path, content, multiple, handleClick, active }: IMenuItem) {
  return (
    <MenuItem active={active ? 1 : 0}>
      {multiple ? (
        <Stack direction="row" onClick={handleClick}>
          <Typography
            variant="h6"
            component="div"
            fontWeight={active ? 700 : 500}
          >
            {content}
          </Typography>
          <KeyboardArrowDownIcon sx={{ mt: '2px' }} />
        </Stack>
      ) : (
        <Link href={path} passHref>
          <Typography
            variant="h6"
            component="a"
            title={content}
            fontWeight={active ? 700 : 500}
          >
            {content}
          </Typography>
        </Link>
      )}
    </MenuItem>
  );
}

export default LinkItem;
