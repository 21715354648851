import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import NightlightIcon from '@mui/icons-material/Nightlight';
// ? Material UI
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// ? Icons
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useDarkMode } from 'next-dark-mode';
// ? Next js
import Link from 'next/link';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toggleMobileDrawer } from 'store/actions/menu';
// ? Assets
import Logo from '../../../logo';
import DrawerMobile from '../drawerMobile';

// ? Custom style
const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const LogoContainer = styled('div')({
  width: 130,
});

interface IMenuProps {
  data: IMenu;
  logo: IMedia;
}

const MenuMobile: React.FC<IMenuProps> = ({ data, logo }) => {
  const dispatch = useDispatch();
  const {
    autoModeActive,
    autoModeSupported,
    darkModeActive,
    switchToAutoMode,
    switchToDarkMode,
    switchToLightMode,
  } = useDarkMode();
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      dispatch(toggleMobileDrawer(open));
    };

  const toggleMode = () => {
    darkModeActive ? switchToLightMode() : switchToDarkMode();
  };

  const modeIcon = darkModeActive ? <LightModeIcon /> : <NightlightIcon />;

  return (
    <Root>
      <DrawerMobile toggleDrawer={toggleDrawer} data={data} />
      <AppBar position="fixed" sx={{ backgroundColor: 'background.paper' }}>
        <Toolbar>
          <Container sx={{ flexGrow: 1, py: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon sx={{ color: 'text.primary' }} />
              </IconButton>
              <LogoContainer>
                <Link href="/">
                  <a>
                    <Logo data={logo} />
                  </a>
                </Link>
              </LogoContainer>
              <IconButton
                edge="start"
                size="small"
                aria-label="mode"
                onClick={toggleMode}
              >
                {modeIcon}
              </IconButton>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
    </Root>
  );
};

export default MenuMobile;
