import ApartmentIcon from '@mui/icons-material/Apartment';
import ArticleIcon from '@mui/icons-material/Article';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
// ? Icons
import CodeIcon from '@mui/icons-material/Code';
import CollectionsIcon from '@mui/icons-material/Collections';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TranslateIcon from '@mui/icons-material/Translate';
import WorkIcon from '@mui/icons-material/Work';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSwitchLang, useTypedSelector } from 'hooks';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleMobileDrawer } from 'store/actions/menu';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';

interface IDrawer {
  toggleDrawer(el: boolean): React.ReactEventHandler<{}>;
  data: IMenu;
}

const DrawerMobile: React.FC<IDrawer> = ({ toggleDrawer, data }: IDrawer) => {
  const open = useTypedSelector((state) => state.menu.showMobile);
  const dispatch = useDispatch();
  const [collapse, setCollapse] = React.useState(false);
  const router = useRouter();
  const switchLang = useSwitchLang();
  const { locale } = useRouter();

  const handleCollapse = () => {
    dispatch(toggleMobileDrawer(true));
    setCollapse(!collapse);
  };

  const handleSwitch = (url: string) => {
    dispatch(toggleMobileDrawer(false));
    router.push(url);
  };

  const isActive = (url: string): boolean => {
    return router.route === url;
  };

  const buttonsIcons = [
    {
      icon: <OpenInNewIcon />,
    },
    {
      icon: <CodeIcon />,
    },
  ];

  const menuItemsIcon = [
    { icon: <HomeIcon /> },
    { icon: <DesignServicesIcon /> },
    { icon: <DynamicFormIcon /> },
    { icon: <CorporateFareIcon /> },
  ];

  const subMenuIcon = [
    { icon: <ContactSupportIcon /> },
    { icon: <CollectionsIcon /> },
    { icon: <ArticleIcon /> },
    { icon: <WorkIcon /> },
    { icon: <LocalLibraryIcon /> },
    { icon: <QuestionAnswerTwoToneIcon /> },
  ];

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box sx={{ width: '100vw', height: '100vh' }} role="menu drawer">
        <Toolbar>
          <Container sx={{ flexGrow: 1, py: 1 }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon sx={{ color: 'text.primary' }} />
            </IconButton>
          </Container>
        </Toolbar>
        <Divider />
        <List>
          {data.menuItems.slice(0, 4).map((el, index) => (
            <ListItem
              button
              key={el._id}
              selected={isActive(el.path)}
              onClick={() => handleSwitch(el.path)}
            >
              <ListItemIcon>{menuItemsIcon[index]?.icon}</ListItemIcon>
              <ListItemText primary={el.label} />
            </ListItem>
          ))}
        </List>

        <ListItemButton onClick={handleCollapse}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary={data.menuItems[4].label} />
          {collapse ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={collapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ bgcolor: 'action.hover' }}>
            {data.subMenuItems.map((el, index) => (
              <ListItem
                button
                key={el._id}
                selected={isActive(el.path)}
                onClick={() => handleSwitch(el.path)}
                sx={{ pl: 8 }}
              >
                <ListItemIcon>{subMenuIcon[index]?.icon}</ListItemIcon>
                <ListItemText primary={el.title} />
              </ListItem>
            ))}
          </List>
        </Collapse>

        <List>
          {data.menuItems.slice(5, 6).map((el, index) => (
            <ListItem
              button
              key={el._id}
              selected={isActive(el.path)}
              onClick={() => handleSwitch(el.path)}
            >
              <ListItemIcon>
                <CallIcon />
              </ListItemIcon>
              <ListItemText primary={el.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {data.buttons.map((el, i) => (
            <ListItem button key={el._id} onClick={() => handleSwitch(el.path)}>
              <ListItemIcon>{buttonsIcons[i].icon}</ListItemIcon>
              <ListItemText primary={el.label} />
            </ListItem>
          ))}
        </List>

        <Divider />

        <Container sx={{ flexGrow: 1, p: 2 }}>
          <Typography variant="h6">
            {locale === 'en' ? 'Language' : 'Langage'}
          </Typography>
          <ButtonGroup
            disableElevation
            variant="outlined"
            color="primary"
            sx={{ my: 2 }}
          >
            <Tooltip
              title={
                locale === 'fr'
                  ? 'Le site est en français'
                  : 'Click to switch to French'
              }
              arrow
            >
              <Button
                variant={locale === 'fr' ? 'contained' : 'outlined'}
                disableElevation
                startIcon={<TranslateIcon />}
                onClick={() => {
                  switchLang('fr');
                  dispatch(toggleMobileDrawer(false));
                }}
              >
                {locale === 'en' ? 'French' : 'Français'}
              </Button>
            </Tooltip>
            <Tooltip
              title={
                locale === 'en'
                  ? 'The website is in English'
                  : 'Cliquez pour passer à l’anglais'
              }
              arrow
            >
              <Button
                variant={locale === 'en' ? 'contained' : 'outlined'}
                onClick={() => {
                  switchLang('en');
                  dispatch(toggleMobileDrawer(false));
                }}
                startIcon={<TranslateIcon />}
              >
                {locale === 'en' ? 'English' : 'Anglais'}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Container>
      </Box>
    </SwipeableDrawer>
  );
};

export default DrawerMobile;
