import Brightness4Icon from '@mui/icons-material/Brightness4';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import TranslateIcon from '@mui/icons-material/Translate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSwitchLang, useTypedSelector } from 'hooks';
import { useDarkMode } from 'next-dark-mode';
import { useRouter } from 'next/router';
import React from 'react';

interface IDrawer {
  toggleDrawer(el: boolean): React.ReactEventHandler<{}>;
}

const DrawerDesk = ({ toggleDrawer }: IDrawer) => {
  const open = useTypedSelector((state) => state.menu.showDesk);
  const switchLang = useSwitchLang();
  const { locale } = useRouter();

  const {
    autoModeActive,
    autoModeSupported,
    darkModeActive,
    switchToAutoMode,
    switchToDarkMode,
    switchToLightMode,
  } = useDarkMode();

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box
        sx={{ width: 430 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Toolbar>
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
              <SettingsIcon color="primary" />
              <Typography variant="h6">
                {locale === 'en' ? 'Setting' : 'Paramètre'}
              </Typography>
            </Stack>
          </Toolbar>
          <Divider />
          <Container sx={{ flexGrow: 1, p: 2 }}>
            <Typography variant="h6">Mode</Typography>
            <ButtonGroup
              disableElevation
              variant="outlined"
              color="primary"
              sx={{ my: 2 }}
            >
              <Tooltip
                title={
                  !darkModeActive && !autoModeActive
                    ? locale === 'en'
                      ? 'You are in light mode'
                      : 'Vous êtes en mode clair'
                    : locale === 'en'
                    ? 'Click to switch to light mode'
                    : 'Cliquez pour passer en mode clair'
                }
                arrow
              >
                <Button
                  onClick={() => switchToLightMode()}
                  variant={
                    !darkModeActive && !autoModeActive
                      ? 'contained'
                      : 'outlined'
                  }
                  endIcon={<BrightnessHighIcon />}
                >
                  {locale === 'en' ? 'Light' : 'Clair'}
                </Button>
              </Tooltip>
              {autoModeSupported && (
                <Tooltip
                  title={
                    autoModeActive
                      ? locale === 'en'
                        ? "You are in system's mode"
                        : 'Vous êtes en mode du système'
                      : locale === 'en'
                      ? "Click to switch to system's mode"
                      : 'Cliquez pour passer en mode du système'
                  }
                  arrow
                >
                  <Button
                    onClick={() => switchToAutoMode()}
                    variant={autoModeActive ? 'contained' : 'outlined'}
                    endIcon={<SettingsBrightnessIcon />}
                  >
                    Auto
                  </Button>
                </Tooltip>
              )}
              <Tooltip
                title={
                  darkModeActive && !autoModeActive
                    ? locale === 'en'
                      ? 'You are in dark mode'
                      : 'Vous êtes en mode sombre'
                    : locale === 'en'
                    ? 'Click to switch to dark mode'
                    : 'Cliquez pour passer en mode sombre'
                }
                arrow
              >
                <Button
                  onClick={() => switchToDarkMode()}
                  variant={
                    darkModeActive && !autoModeActive ? 'contained' : 'outlined'
                  }
                  endIcon={<Brightness4Icon />}
                >
                  {locale === 'en' ? 'Dark' : 'Sombre'}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Container>
          <Divider />
          <Container sx={{ flexGrow: 1, p: 2 }}>
            <Typography variant="h6">
              {locale === 'en' ? 'Language' : 'Langage'}
            </Typography>
            <ButtonGroup
              disableElevation
              variant="outlined"
              color="primary"
              sx={{ my: 2 }}
            >
              <Tooltip
                title={
                  locale === 'fr'
                    ? 'Le site est en français'
                    : 'Click to switch to French'
                }
                arrow
              >
                <Button
                  variant={locale === 'fr' ? 'contained' : 'outlined'}
                  disableElevation
                  startIcon={<TranslateIcon />}
                  onClick={() => switchLang('fr')}
                >
                  {locale === 'en' ? 'French' : 'Français'}
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  locale === 'en'
                    ? 'The website is in English'
                    : 'Cliquez pour passer à l’anglais'
                }
                arrow
              >
                <Button
                  variant={locale === 'en' ? 'contained' : 'outlined'}
                  onClick={() => switchLang('en')}
                  startIcon={<TranslateIcon />}
                >
                  {locale === 'en' ? 'English' : 'Anglais'}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Container>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default DrawerDesk;
