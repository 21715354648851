import { MenuDesk, MenuMobile } from './components';

interface IProps {
  data: IMenu;
  logo: any;
}

const Menu: React.FC<IProps> = ({ data, logo }) => {
  return (
    <>
      <MenuMobile data={data} logo={logo} />
      <MenuDesk data={data} logo={logo} />
    </>
  );
};

export default Menu;
